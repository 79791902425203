// src/components/header.js
import React from "react"
import { Link } from "gatsby"

import LOGO_IMG from "../images/logo.png"
import "../header.css"
export default function Header() {
    return (
        <header>
            <Link to="/">
                <img src={LOGO_IMG} className="logo-image" alt="logo"></img>
            </Link>
        </header>
    )
}